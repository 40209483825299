<template>
    <div class="modal-dialog">
        <div class="modal-header">
            <h3 class="block-title">
                Добавить сотрудника в отдел
                <span :title="struct_name">{{ struct_name }}</span>
            </h3>
            <div class="block-options">
                <button
                    type="button"
                    class="close-modal"
                    @click="$modal.hide('AddUser')"
                >
                    ×
                </button>
            </div>
        </div>
        <div class="modal-main" ref="modal-main">
            <vue-progress-bar />
            <form
                @change="onFormChange"
                class="users"
            >
                <div class="users__list">
                    <div
                        v-for="variable in usersList"
                        :key="variable.id"
                        class="users__item"
                    >
                        <label
                            :for="`user-${variable.id}`"
                            class="users__row"
                        >
                            <div class="users__col --checkbox">
                                <div
                                    class="users-checkbox"
                                >
                                    <input
                                        class="users-checkbox__field"
                                        type="checkbox"
                                        :value="variable.id"
                                        :id="`user-${variable.id}`"
                                        :checked="variable.structure.some((item) => item.id === struct_id)"
                                        :data-user-params="JSON.stringify(variable)"
                                    >
                                    <span class="users-checkbox__replace"></span>
                                </div>
                            </div>

                            <div class="users__col --name">
                                <router-link
                                    tag="a"
                                    :to="`/auth/profile/${variable.id}/?staff=1`"
                                    target="_blank"
                                    class="users__name"
                                >
                                    {{ variable.full_name }}
                                </router-link>
                                <p class="users__position" v-if="variable.positions">
                                    {{variable.positions.name}}
                                </p>
                            </div>

                            <div class="users__col">
                                <ul class="users-contacts">
                                    <li
                                        v-if="variable.email && variable.email.trim() !== ''"
                                        :title="variable.email"
                                        class="users-contacts__item --email"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
                                            <path d="M504.888 111.933L264.994 297.997c-2.666 2.007-5.83 3.003-8.994 3.003s-6.328-.996-8.994-3.003L7.112 111.933C2.664 118.909 0 127.13 0 136v240c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45V136c0-8.87-2.664-17.091-7.112-24.067z"></path>
                                            <path d="M467 91H45c-4.618 0-8.987.901-13.184 2.199L256 267.25 480.184 93.199C475.987 91.901 471.618 91 467 91z"></path>
                                        </svg>
                                        {{ variable.email }}
                                    </li>
                                    <li
                                        v-if="variable.phone_number && variable.phone_number.trim() !== ''"
                                        class="users-contacts__item"
                                    >
                                        <svg x="0px" y="0px" width="13px" height="13px" viewBox="0 0 401.998 401.998">
                                            <path d="M401.129,311.475c-1.137-3.426-8.371-8.473-21.697-15.129c-3.61-2.098-8.754-4.949-15.41-8.566
                                        c-6.662-3.617-12.709-6.95-18.13-9.996c-5.432-3.045-10.521-5.995-15.276-8.846c-0.76-0.571-3.139-2.234-7.136-5
                                        c-4.001-2.758-7.375-4.805-10.14-6.14c-2.759-1.327-5.473-1.995-8.138-1.995c-3.806,0-8.56,2.714-14.268,8.135
                                        c-5.708,5.428-10.944,11.324-15.7,17.706c-4.757,6.379-9.802,12.275-15.126,17.7c-5.332,5.427-9.713,8.138-13.135,8.138
                                        c-1.718,0-3.86-0.479-6.427-1.424c-2.566-0.951-4.518-1.766-5.858-2.423c-1.328-0.671-3.607-1.999-6.845-4.004
                                        c-3.244-1.999-5.048-3.094-5.428-3.285c-26.075-14.469-48.438-31.029-67.093-49.676c-18.649-18.658-35.211-41.019-49.676-67.097
                                        c-0.19-0.381-1.287-2.19-3.284-5.424c-2-3.237-3.333-5.518-3.999-6.854c-0.666-1.331-1.475-3.283-2.425-5.852
                                        s-1.427-4.709-1.427-6.424c0-3.424,2.713-7.804,8.138-13.134c5.424-5.327,11.326-10.373,17.7-15.128
                                        c6.379-4.755,12.275-9.991,17.701-15.699c5.424-5.711,8.136-10.467,8.136-14.273c0-2.663-0.666-5.378-1.997-8.137
                                        c-1.332-2.765-3.378-6.139-6.139-10.138c-2.762-3.997-4.427-6.374-4.999-7.139c-2.852-4.755-5.799-9.846-8.848-15.271
                                        c-3.049-5.424-6.377-11.47-9.995-18.131c-3.615-6.658-6.468-11.799-8.564-15.415C98.986,9.233,93.943,1.997,90.516,0.859
                                        C89.183,0.288,87.183,0,84.521,0c-5.142,0-11.85,0.95-20.129,2.856c-8.282,1.903-14.799,3.899-19.558,5.996
                                        c-9.517,3.995-19.604,15.605-30.264,34.826C4.863,61.566,0.01,79.271,0.01,96.78c0,5.135,0.333,10.131,0.999,14.989
                                        c0.666,4.853,1.856,10.326,3.571,16.418c1.712,6.09,3.093,10.614,4.137,13.56c1.045,2.948,2.996,8.229,5.852,15.845
                                        c2.852,7.614,4.567,12.275,5.138,13.988c6.661,18.654,14.56,35.307,23.695,49.964c15.03,24.362,35.541,49.539,61.521,75.521
                                        c25.981,25.98,51.153,46.49,75.517,61.526c14.655,9.134,31.314,17.032,49.965,23.698c1.714,0.568,6.375,2.279,13.986,5.141
                                        c7.614,2.854,12.897,4.805,15.845,5.852c2.949,1.048,7.474,2.43,13.559,4.145c6.098,1.715,11.566,2.905,16.419,3.576
                                        c4.856,0.657,9.853,0.996,14.989,0.996c17.508,0,35.214-4.856,53.105-14.562c19.219-10.656,30.826-20.745,34.823-30.269
                                        c2.102-4.754,4.093-11.273,5.996-19.555c1.909-8.278,2.857-14.985,2.857-20.126C401.99,314.814,401.703,312.819,401.129,311.475z"></path>
                                        </svg>
                                        {{ variable.phone_number }}
                                    </li>
                                </ul>
                            </div>

                            <div class="users__col">
                                <ul class="users-structures">
                                    <li
                                        v-for="structureItem in variable.structure"
                                        :title="structureItem.name"
                                        class="users-structures__item"
                                    >{{ structureItem.name }}</li>
                                </ul>
                            </div>
                        </label>
                    </div>
                </div>
            </form>
            <loading-spinner
                v-if="isLoading"
                class="wall__spinner"
            />
            <mugen-scroll
                scroll-container="modal-main"
                :threshold="1"
                :handler="scrollFetchData"
                :should-handle="!isLoading"
            />
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import MugenScroll from 'vue-mugen-scroll';
    import LoadingSpinner from '@/components/LoadingSpinner';

    export default {
        name: 'CreateStructure',
        components: {
            MugenScroll,
            LoadingSpinner
        },
        props: {
            struct_id: {
                type: Number,
                required: false
            },
            struct_name: {
                type: String
            },
            valueUpdated: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                usersList: [],
                isLoading: false,
                isRequestURL: '/api/v1/users/?active=works&limit=10'
            };
        },
        methods: {
            scrollFetchData() {
                if (this.isRequestURL !== false) {
                    this.isLoading = true;

                    setTimeout(async () => {
                        await this.loadList();
                    }, 800);
                }
            },

            async loadList() {
                session.get(this.isRequestURL)
                    .then((response) => {
                        if (response.data.next !== null) {
                            const urlNext = new URL(response.data.next);
                            this.isRequestURL = urlNext.pathname + urlNext.search;
                        } else {
                            this.isRequestURL = false;
                        }

                        this.usersList = this.usersList.concat(response.data.results);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onFormChange(event) {
                let userInfo = {};
                let textToast = 'Пользователь добавлен в отдел';
                const target = event.target;
                const userId = target.value;

                try {
                    userInfo = await session.get(`/api/v1/staff/${userId}/`);
                    userInfo = userInfo.data;

                    if (target.checked === true) {
                        userInfo.structure.push(this.struct_id);
                        textToast = 'Пользователь добавлен в отдел';
                    } else {
                        const structureIndex = userInfo.structure.indexOf(this.struct_id);
                        if (structureIndex !== -1) {
                            userInfo.structure.splice(structureIndex, 1);
                            textToast = 'Пользователь удалён из отдела';
                        }
                    }

                    const result = await session.put(`/api/v1/staff/${userId}/`, userInfo);
                    this.$swal({
                        title: 'Отлично!',
                        text: textToast,
                        icon: 'success',
                        position: 'top-end',
                        backdrop: false,
                        showConfirmButton: false,
                        toast: true,
                        timer: 1400,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        customClass: {
                            container: 'add-user-toast'
                        }
                    });
                    this.valueUpdated(result.data, target.checked);
                } catch (error) {
                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При обработке запроса произошла ошибка на сервере, давайте попробуем ещё раз.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400
                    });
                    console.error(error);
                }
            }
        }
    };
</script>

<style lang="scss">
    @import '#sass/v-style';

    [data-modal="AddUser"] {
        .v--modal {
            &-background-click {
                height: 100%;
                padding: 20px 0px;
                display: flex;
                justify-content: center;
            }

            &-box {
                position: inherit;
            }
        }

        .modal {
            &-dialog {
                margin: 0px;
                height: 100%;
                max-width: 100%;
                pointer-events: unset;
                display: flex;
                flex-direction: column;
            }

            &-main {
                overflow-y: auto;
                padding: 0px 24px 24px;
            }
        }

        .block {
            &-title {
                display: flex;
                align-items: center;
                span {
                    overflow: hidden;
                    max-width: 320px;
                    margin-left: 5px;
                    display: inline-block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @media screen and (max-width: 480px) {
                        display: none;
                    }
                }
            }
        }
    }

    .add-user-toast {
        .swal2 {
            &-title {
                text-align: left;
            }
        }
    }

    .users {
        $p: &;
        display: flex;
        overflow-x: auto;
        &__list {
            flex-grow: 1;
        }

        &__item {
            & + #{$p}__item {
                border-top: 1px solid #e8e8e8;
            }

            &:hover {
                background-color: #f6f7f9;
            }
        }

        &__row {
            display: flex;
            cursor: pointer;
            margin: 0px;
            padding: 15px 0px;
            font-weight: normal;
        }

        &__col {
            margin: 10px;
            &:not(.--checkbox) {
                flex-basis: calc((100% / 3) - 20px);
            }
            &.--name {
                min-width: 140px;
            }
        }

        &-checkbox {
            $ps: &;
            position: relative;
            &__field {
                top: 0px;
                left: 0px;
                position: absolute;
                width: .5px;
                height: .5px;
                opacity: 0;
                &:checked {
                    & + #{$ps}__replace {
                        background-color: $gold-main;
                        &:before,
                        &:after {
                            background-color: #fff;
                            transform: rotate(-45deg);
                        }
                        &:before {
                            width: 11px;
                            left: 35%;
                        }
                        &:after {
                            height: 6px;
                            left: 30%;
                        }
                    }
                }
            }
            &__replace {
                width: 22px;
                height: 22px;
                display: block;
                position: relative;
                border-radius: 100px;
                background-color: #f5f5f5;
                &:before,
                &:after {
                    content: "";
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    background-color: #ccc;
                    transform: translate(-50%, -50%);
                }
                &:before {
                    width: 10px;
                    height: 2px;
                }
                &:after {
                    width: 2px;
                    height: 10px;
                }
            }
        }

        &__name {
            color: #2173d3;
            font-size: 1rem;
            font-weight: 600;
        }

        &__position {
            margin: 0px;
            color: #999999;
            font-size: 0.875rem;
        }

        &-contacts {
            $ps: &;
            white-space: nowrap;
            &__item {
                svg {
                    width: 16px;
                    min-width: 16px;
                    margin: 0px 5px 0px 0px;
                }

                & + #{$ps}__item {
                    margin-top: 5px;
                }

                &.--email {
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &-structures {
            white-space: nowrap;
            &__item {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
</style>
